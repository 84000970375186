var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "".concat(_vm.formData.id ? '编辑' : '创建', "\u9879\u76EE\u89C6\u9891"),
      "append-to-body": "",
      "width": "500px",
      "top": "50px",
      "close-on-click-modal": false
    },
    on: {
      "close": _vm.resetForm
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "80px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "视频标题",
      "prop": "title",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "封面图",
      "prop": "corverImage",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("建议尺寸400px *300px，大小1M以内")]), _c('f-upload', {
    attrs: {
      "type": 2,
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "default-file-list": _vm.formData.corverImage ? [_vm.formData.corverImage] : []
    },
    on: {
      "on-success": function onSuccess(e) {
        return _vm.uploadSuccess(e, 'image');
      }
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "上传视频",
      "prop": "filePath",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('div', {
    staticClass: "video-upload-wrap"
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("大小建议不超过20M")]), !_vm.showVideoProgress && !_vm.formData.fileName ? _c('p', {
    staticClass: "no-video-tips"
  }, [_c('i', {
    staticClass: "el-icon-info"
  }), _vm._v("未选择文件 ")]) : _vm._e(), _vm.showVideoProgress ? _c('el-progress', {
    staticClass: "video-progress",
    attrs: {
      "percentage": _vm.videoUploadProgress,
      "stroke-width": 12,
      "width": 100
    }
  }) : _vm._e(), _vm.formData.fileName ? _c('p', {
    staticClass: "video-name"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../assets/video.png")
    }
  }), _vm._v(_vm._s(_vm.formData.fileName) + " ")]) : _vm._e(), _c('f-upload', {
    attrs: {
      "type": 6,
      "button-text": "选择视频",
      "accept": "video/mp4,video/avi,video/mov,video/wmv",
      "show-process": "",
      "limit-size": 300
    },
    on: {
      "on-success": function onSuccess(e) {
        return _vm.uploadSuccess(e, 'video');
      },
      "on-uploading": _vm.videUploading
    }
  }), _vm.showVideoProgress ? _c('div', {
    staticClass: "mask"
  }) : _vm._e()], 1)]), _c('el-form-item', {
    attrs: {
      "label": "排序",
      "prop": "sort"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "数字越大越靠前",
      "type": "number"
    },
    model: {
      value: _vm.formData.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sort", $$v);
      },
      expression: "formData.sort"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "是否推荐",
      "prop": "recommend"
    }
  }, [_c('el-switch', {
    model: {
      value: _vm.formData.recommend,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "recommend", $$v);
      },
      expression: "formData.recommend"
    }
  })], 1), _vm.formData.recommend ? _c('el-form-item', {
    attrs: {
      "label": "推荐标签",
      "prop": "recommendTag"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.recommendTag,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "recommendTag", $$v);
      },
      expression: "formData.recommendTag"
    }
  })], 1) : _vm._e()], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("保存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }